'use client';

import React, { Component, ReactNode } from 'react';

// Temporary ErrorBoundary to catch errors in the shell for easier debugging
interface ErrorBoundaryProps {
    children: ReactNode;
    fallback: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Catch errors in any child components and re-renders with an error message
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            // Fallback UI when an error occurs
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    {/* <p>Please try refreshing the page or come back later.</p>
          <p>{this.state.error && this.state.error.toString()}</p>
          <p>{this.state?.errorInfo?.componentStack}</p> */}
                </div>
            );
        }

        // Render children if there's no error
        return this.props.children;
    }
}
export default ErrorBoundary;
