'use client';

import React from 'react';

import {
    AudioOptions,
    MobileNav,
    VideoOptions,
    useMedia,
    MainNavProps,
    TemplateOptions,
} from '@videoblocks/shared-components';

import { UserData } from '../../types';
import ErrorBoundary from '../ErrorBoundary';
import { imagesUrl } from '../lib/constants';

//Returns an object of subCategories for each middleMenuCategory.
const getNavSubCategoriesForMobile = (menuCategories, topLevelCategories) => {
    return menuCategories.reduce((accum, middleMenuCategory) => {
        const categories = topLevelCategories[middleMenuCategory]?.categories;
        const subCategory = {};
        if (categories) {
            Object.keys(categories).forEach((category) => {
                const { subCategories } = categories[category];
                if (subCategories.length) {
                    subCategory[category] = subCategories;
                }
            });
            accum[middleMenuCategory] = subCategory;
        }
        return accum;
    }, {});
};
// TODO: add this const to shared-components
const mobileRightMenu = [
    'stock-footage',
    'animated-backgrounds',
    'sound-effects',
    'Templates',
    'Images',
    'Collections',
    'enterprise',
    'account',
];

const MobileNavContainer = (props: UserData) => {
    const {
        auth: { user, isIpAuth, isLoggedIn, featureFlags },
        ui: {
            nav: {
                menu: { collections, top_level_categories, account, showFavorites },
            },
        },
    } = props;

    const subMenuCategories = getNavSubCategoriesForMobile(mobileRightMenu, top_level_categories);

    const mobileNavProps: MainNavProps = {
        businessCategories: top_level_categories.business.categories as any, // TODO: update shared-components to use the correct type
        user,
        showFavorites,
        isLoggedIn,
        isIpAuth,
        top_level_categories,
        enterpriseSubCategories: top_level_categories?.business?.categories?.enterprise.subCategories,
        resourcesCategories: top_level_categories?.resources,
        imagesUrl,
        constants: {
            videoOptions: VideoOptions,
            audioOptions: AudioOptions,
            templateOptions: TemplateOptions,
        },
        account,
        subMenuCategories,
        collections,
        useMedia,
    };

    return (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <MobileNav {...mobileNavProps} />
        </ErrorBoundary>
    );
};

export default MobileNavContainer;
