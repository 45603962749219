import { UserData } from '../../types';

function getInitials(fullName) {
    const names = (fullName || '').trim().split(' ');
    return names.map((name) => name.charAt(0).toUpperCase()).join('');
}

export function getRightSideMenuProps(props: UserData) {
    try {
        const {
            auth: {
                user,
                isLoggedIn,
                isIpAuth,
                subscription,
                permissions: { storyboardEnabled },
                featureFlags: { useSnapshotsUI }, // Add a feature flag here to make it available in Builder
            },
            ui: {
                nav: {
                    menu: {
                        account,
                        organizationName,
                        planCta,
                        remainingExports,
                        shouldShowEnterpriseExperience,
                        showFavorites,
                        starterPlanRemainingDownloads,
                    },
                },
            },
        } = props;
        const isUserOnGrowthPlan = subscription?.planClassification === 'Growth';
        const isUserOnStarterPlan = subscription?.planClassification === 'Starter';
        const userInitials = isLoggedIn ? getInitials(user?.displayName) : '';

        return {
            user,
            isLoggedIn,
            isIpAuth,
            storyboardEnabled,
            account,
            organizationName,
            planCta,
            remainingExports,
            shouldShowEnterpriseExperience,
            shouldUseSnapshotsUI: useSnapshotsUI,
            showFavorites,
            starterPlanRemainingDownloads,
            isUserOnGrowthPlan,
            isUserOnStarterPlan,
            userInitials,
        };
    } catch (error) {
        console.error('Error destructuring props:', error);
        // return default values
        return {
            user: {
                id: 0,
                email: '',
                displayName: '',
            },
            isLoggedIn: false,
            isIpAuth: false,
            storyboardEnabled: false,
            account: undefined,
            organizationName: '',
            planCta: undefined,
            remainingExports: 0,
            shouldShowEnterpriseExperience: false,
            shouldUseSnapshotsUI: false,
            showFavorites: false,
            starterPlanRemainingDownloads: 0,
            isUserOnGrowthPlan: false,
            isUserOnStarterPlan: false,
            userInitials: '',
        };
    }
}
